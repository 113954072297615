import React, { useState, useEffect, useRef } from "react"
import ReactMarkdown from "react-markdown"
import { Card } from "../card/card"
import { CardSlider } from "../card-slider/card-slider"
import { Button } from "../buttons/buttons"

export const CardSliderDuo = ({ values }) => {
  const [state, setState] = useState({
    nav1: null,
    nav2: null,
  })
  const slider1 = useRef()
  const slider2 = useRef()

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])

  const { nav1, nav2 } = state

  return (
    <div className="card-slider-duo">
      <div className="row row-5">
        <div className="col-bg col-md-6 order-md-last mb-sm-down-4">
          <CardSlider
            asNavFor={nav1}
            forwardRef={slider => (slider2.current = slider)}
            noGutter
            visibleInitial={false}
            visibleLgDown={false}
            visibleMdDown={false}
            visibleSmDown={false}
            arrowsMdDown={true}
            arrowsSmDown={true}
            showInitial={1}
            showLgDown={1}
            showMdDown={1}
            showSmDown={1}
          >
            {values.map((value, i) => {
              return (
                <Card
                  forwardKey={i}
                  variant="boxless"
                  img={value.img}
                  imgHeight="h-ratio-1-1"
                  className="no-zoom"
                />
              )
            })}
          </CardSlider>
        </div>
        <div className="col-body col-md-6 order-md-first">
          <CardSlider
            asNavFor={nav2}
            forwardRef={slider => (slider1.current = slider)}
            fade
            visibleInitial={false}
            visibleLgDown={false}
            visibleMdDown={false}
            visibleSmDown={false}
            showInitial={1}
            showLgDown={1}
            showMdDown={1}
            showSmDown={1}
            arrowsInitial={false}
            arrowsLgDown={false}
          >
            {values.map((value, i) => {
              return (
                <div className="col-body-content" key={i}>
                  <h4 className="h4">{value.title}</h4>
                  {typeof value.text === "string" && (
                    <>
                      <ReactMarkdown source={value.text} escapeHtml={false} />
                      <ReactMarkdown source={value.name} escapeHtml={false} />
                    </>
                  )}
                  {typeof value.text === "object" &&
                    value.text.map((item, index) => {
                      return (
                        <ReactMarkdown
                          source={item}
                          escapeHtml={false}
                          key={`p${i}${index}`}
                        />
                      )
                    })}
                  {value.buttons && (
                    <div className="content-v-center-left">
                      {value.buttons.innerBlocks.map((value, i) => {
                        return (
                          <Button
                            variant="link"
                            link={value.attributes.url}
                            key={`button${i}`}
                          >
                            {value.attributes.text}
                          </Button>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </CardSlider>
        </div>
      </div>
    </div>
  )
}
